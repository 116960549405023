<template>
  <section id="orders">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="4" md="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search..." @keyup.enter="searchOrder" />
          </b-input-group>
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedTaxType" label="title" placeholder="Tax Type" :options="allTaxTypes"
            @input="changeSelectedTaxType" />
        </b-col>
        <b-col xl="3" md="3">
          <v-select v-model="selectedDeliveryType" label="title" placeholder="Delivery Type" :options="allDeliveryTypes"
            @input="changeSelectedDeliveryType" />
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col xl="3" md="3">
          <v-select v-model="selectedContactPerson" label="title" placeholder="Contact Person"
            :options="allContactPersons" @input="changeSelectedContactPerson" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showOrders" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalTurnover | formatNumber }}
                </h2>
                <span>Total Turnover</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalMargin | formatNumber }}
                </h2>
                <span>Total Margin</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2"></b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Orders
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders" />
          </h4>
          <b-popover target="popover-orders" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer" @click="exportTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="ordersTable.currentPage"
          :items="ordersTable.items" :fields="ordersTable.fields" :sort-by.sync="ordersTable.sortBy"
          :sort-desc.sync="ordersTable.sortDesc" :sort-direction="ordersTable.sortDirection"
          :filter="ordersTable.filter" :filter-included-fields="ordersTable.filterOn" @sort-changed="sortChanged"
          @row-clicked="rowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="ordersTable.currentPage" :total-rows="ordersTable.totalRows" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0" @change="handleOrdersTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="order-details-modal" size="xl" title="Order details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @shown="onOrderDetailsModalShown">
      <b-overlay :show="showOrderDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-row>
          <b-col xl="9">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Order details
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-order-details" />
                </h4>
                <b-popover target="popover-order-details" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <b-list-group>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>Order no.</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.order_no }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>Ext. order no.</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.ext_order_no }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>Order date</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.order_date }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>Invoice no.</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.invoice_no }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>Invoice date</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.invoice_date }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>Company name</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.company_name }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>Payment</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.payment }} €</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>- Taxes</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderTaxAmount }} €</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>= Turnover</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.turnover }} €</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>- Purchasing price</b></span>
                    </b-col>
                    <b-col xl="4" md="4">
                      <span>{{ orderDetails.pprice }} €</span>
                    </b-col>
                    <b-col xl="6" md="6">
                      <span>{{ (100 - orderDetails.margin_p).toFixed(2) }} %</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>- Payment fee</b></span>
                    </b-col>
                    <b-col xl="4" md="4">
                      <span>0.0 €</span>
                    </b-col>
                    <b-col xl="2" md="2" />
                    <b-col xl="4" md="4">
                      <span>{{ orderDetails.payment_name }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>- Platform costs</b></span>
                    </b-col>
                    <b-col xl="4" md="4">
                      <span>0.0 €</span>
                    </b-col>
                    <b-col xl="2" md="2" />
                    <b-col xl="4" md="4">
                      <span>{{ orderDetails.platform_name }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>- Other costs</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>0.0 €</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>- Pick & Pack costs</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>0.0 €</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>- Shipping costs</b></span>
                    </b-col>
                    <b-col xl="10" md="10">
                      <span>{{ orderDetails.shipping_cost }} €</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item>
                  <b-row>
                    <b-col xl="2" md="2">
                      <span><b>= Margin</b></span>
                    </b-col>
                    <b-col xl="4" md="4">
                      <span>{{ orderDetails.margin }} €</span>
                    </b-col>
                    <b-col xl="6" md="6">
                      <span>{{ orderDetails.margin_p }} %</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-card>
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Order positions
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-order-positions" />
                </h4>
                <b-popover target="popover-order-positions" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <b-table v-if="showOrderPossTable" striped hover responsive class="position-relative"
                :current-page="orderPossTable.currentPage" :items="orderPossTable.items" :fields="orderPossTable.fields"
                :sort-by.sync="orderPossTable.sortBy" :sort-desc.sync="orderPossTable.sortDesc"
                :sort-direction="orderPossTable.sortDirection" />
            </b-card>
          </b-col>
          <b-col xl="3">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Order prices
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-order-prices" />
                </h4>
                <b-popover target="popover-order-prices" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="orderPricesChart" :options="orderPricesChart" style="width: 100%; height: 400px;" />
            </b-card>
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Order compare prices
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-order-compare-prices" />
                </h4>
                <b-popover target="popover-order-compare-prices" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <ECharts ref="orderComparePricesChart" :options="orderComparePricesChart"
                style="width: 100%; height: 400px;" />
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal,
  BListGroup,
  BListGroupItem,
  BButton,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    BListGroup,
    BListGroupItem,
    BButton,
    vSelect,
    flatPickr,
    ECharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showOrders: true,
      showOrderDetails: true,
      showOrderPossTable: false,
      showExportStart: false,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchValue: '',
      selectedTaxType: '',
      allTaxTypes: [],
      selectedDeliveryType: '',
      allDeliveryTypes: [],
      selectedContactPerson: '',
      allContactPersons: [],
      totalTurnover: 0.0,
      totalMargin: 0.0,
      queryParams: {},
      orderDetails: {},
      orderTaxAmount: 0.0,
      ordersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'delivery_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no', sortable: true },
          {
            key: 'pprice',
            label: 'purchasing price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin',
            label: 'margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment',
            label: 'payment',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'profit',
            label: 'profit',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'delivery_type', label: 'delivery type', sortable: true },
          { key: 'delivery_country', label: 'delivery country', sortable: true },
          {
            key: 'delivery_date',
            label: 'delivery date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'employee', label: 'employee', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      orderPricesChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      orderComparePricesChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      orderPossTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'item_sku',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'item_sku', label: 'item sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'pprice',
            label: 'purchasing price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sprice',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'discount',
            label: 'discount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'discounted_price',
            label: 'discounted price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'total_price',
            label: 'total price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'tax',
            label: 'tax',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'tax_value',
            label: 'tax amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getTaxTypes();
      await this.getDeliveryTypes();
      await this.getContactPersons();
      await this.getOrders();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getTaxTypes() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-tax-types/`, {});
        this.allTaxTypes = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getDeliveryTypes() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-delivery-types/`, {});
        this.allDeliveryTypes = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getContactPersons() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-support-employee-names/`, this.queryParams);
        this.allContactPersons = response.data.results.map(item => item.full_name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getOrders() {
      this.showOrders = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders/`, this.queryParams);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.ordersTable.items = results;
          this.ordersTable.totalRows = results[0].count * 2;
          this.totalTurnover = results[0].total_turnover;
          this.totalMargin = results[0].total_margin;
        }
        else {
          this.ordersTable.items = [];
          this.ordersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrders = false;
      }
    },
    async getOrderDetails(orderNo) {
      this.showOrderDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/order-details/`, { order_no: orderNo });
        this.orderDetails = response.data.results[0];
        this.orderPricesChart.xAxis.data = ['Amount'];
        this.orderPricesChart.series[0] = {
          name: 'Purchasing price',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: [this.orderDetails.pprice],
        };
        this.orderPricesChart.series[1] = {
          name: 'Variable costs',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: [this.orderDetails.shipping_cost],
        };
        this.orderPricesChart.series[2] = {
          name: 'Margin',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: [this.orderDetails.margin],
        };

        this.orderComparePricesChart.series[0].data = [
          { value: this.orderDetails.pprice, name: 'Purchasing price' },
          { value: this.orderDetails.shipping_cost, name: 'Shipping cost' }
        ];

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrderDetails = false;
      }
    },
    async getOrderPoss(orderNo) {
      this.showOrderPossTable = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/order/`, { order_no: orderNo });
        this.orderPossTable.items = response.data.results;
        const taxAmounts = response.data.results.map(item => item.tax_value);
        this.orderTaxAmount = taxAmounts.reduce((a, b) => (a + b)).toFixed(2);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrderPossTable = true;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getOrders();
        this.oldDateRange = this.dateRange;
      }
    },
    async searchOrder() {
      this.queryParams.page = 1;
      this.queryParams.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getOrders();
    },
    async changeSelectedTaxType() {
      this.queryParams.tax_type = this.selectedTaxType;
      await this.getOrders();
    },
    async changeSelectedDeliveryType() {
      this.queryParams.delivery_type = this.selectedDeliveryType;
      await this.getOrders();
    },
    async changeSelectedContactPerson() {
      this.queryParams.employee = this.selectedContactPerson;
      await this.getOrders();
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParams.ordering = `-${value.sortBy}`;
      } else {
        this.queryParams.ordering = value.sortBy;
      }
      await this.getOrders();
    },
    async handleOrdersTablePageChange(value) {
      this.queryParams.page = value;
      await this.getOrders();
    },
    async rowClicked(row) {
      this.$refs['order-details-modal'].show();
      await this.getOrderDetails(row.order_no);
      await this.getOrderPoss(row.order_no);
    },
    onOrderDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.orderPricesChart.resize();
        this.$refs.orderComparePricesChart.resize();
      });
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-orders/`, this.queryParams);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'Orders.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
